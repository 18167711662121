<template>
  <div class="d-flex flex-column text-center">
    <v-text-field
      class="mx-auto my-2"
      outlined
      style="max-width: 200px; font-size: 26px"
      v-model="worksheet.documentNumber"
      :label="$vuetify.lang.t('$vuetify.base.documentNumber')"
      @change="$emit('update:worksheet', worksheet)"
    />

    <div class="d-flex justify-center mx-2 my-4">
      <v-btn
        :disabled="worksheet.status  == 'USED'"
        @click="updateWorksheet(worksheet)"
        color="primary"
        >{{ $vuetify.lang.t("$vuetify.base.update") }}</v-btn
      >
    </div>
    <confirmation-dialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    worksheet: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),

    hasEntries() {
      return this.worksheet.entries && this.worksheet.entries.length > 0;
    },
  },
  created() {},
  methods: {
    ...mapActions("processWorksheet", [
      "updateProcessWorksheet",
      "deleteProcessWorksheet",
    ]),
    ...mapActions("messages", ["addErrorMessage", "addSuccessMessage"]),

    updateWorksheet(worksheet) {
      const payload = Object.assign({}, worksheet);
      delete payload.files;
      this.updateProcessWorksheet(payload);

      this.$emit("update:worksheet", worksheet);
      this.$emit("updated", worksheet);
    },
  },
};
</script>

<style></style>
